<template>
  <q-form ref="editForm">
    <c-card title="LBLDETAIL" class="cardClassDetailForm">
      <template slot="card-button">
        <q-btn-group outline >
          <!-- <c-btn 
            v-if="editable && !isOld" 
            label="LBLLOAD" 
            icon="system_update_alt"
            @btnClicked="loadAssessPlan" /> -->
          <!-- 평가계획 저장 LBL0001052 -->
          <c-btn 
            v-if="editable && !disabled" 
            :isSubmit="isSave"
            :url="saveUrl"
            :param="assessPlan"
            :mappingType="mappingType"
            label="LBLSAVE" 
            icon="save"
            @beforeAction="saveAssessPlan"
            @btnCallback="saveCallback" />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <!-- 평가명 -->
          <c-text
            :required="true" 
            :editable="editable"
            :disabled="disabled"
            label="LBL0000367"
            name="assessmentName"
            v-model="assessPlan.assessmentName">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 평가기간 -->
          <c-datepicker
            :required="true" 
            :editable="editable"
            :disabled="disabled"
            :range="true"
            label="LBL0000607"
            name="period"
            v-model="assessPlan.assessmentPeriod"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 평가년도 -->
          <c-datepicker
            :required="true" 
            :editable="editable"
            :disabled="disabled"
            default="today"
            type="year"
            label="LBL0001000"
            name="assessmentYear"
            v-model="assessPlan.assessmentYear"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 평가구분-정기/수시/최초 -->
          <c-select
            :required="true" 
            :editable="editable"
            :disabled="disabled"
            codeGroupCd="RAM_ASSESS_TYPE_CD"
            type="edit"
            itemText="codeName"
            itemValue="code"
            name="ramAssessTypeCd"
            label="LBL0001041"
            v-model="assessPlan.ramAssessTypeCd"
          ></c-select>
        </div>
        <!-- K-PSR, Check-list, 3단계판단법은 표시하지 않음 -->
        <div v-if="assessPlan.ramTechniqueCd !== 'RT00000005' && assessPlan.ramTechniqueCd !== 'RT00000010' && assessPlan.ramTechniqueCd !== 'RT00000035'" class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            :required="true" 
            :editable="editable"
            :disabled="disabled"
            :comboItems="matrixItems"
            type="edit"
            itemText="matrixName"
            itemValue="ramMatrixId"
            name="ramMatrixId"
            label="Matrix"
            v-model="assessPlan.ramMatrixId"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 주관 -->
          <c-field 
            :required="true" 
            :editable="editable"
            :disabled="disabled"
            :data="assessPlan"
            deptValue="assessmentManageDeptCd"
            type="dept_user" 
            label="LBL0001053" 
            name="assessmentManageUserId" 
            v-model="assessPlan.assessmentManageUserId" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant 
            :required="true" 
            :disabled="disabled"
            :editable="editable" 
            type="edit" 
            name="plantCd" 
            v-model="assessPlan.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-text
            :editable="editable"
            :disabled="true"
            label="LBLREGUSERNDATE"
            name="request"
            v-model="request">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 외부기관 -->
          <c-vendor
            :disabled="disabled"
            :editable="editable"
            label="LBL0001054"
            name="vendorCd"
            v-model="assessPlan.vendorCd" />
        </div>
        <!-- JSA만 표시 -->
        <div v-if="assessPlan.ramTechniqueCd === 'RT00000015'" class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 작업지역 -->
          <c-text
            :editable="editable"
            :disabled="disabled"
            label="LBL0001055"
            name="workArea"
            v-model="assessPlan.workArea">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <!-- 상세내용 -->
          <c-textarea
            :editable="editable"
            :disabled="disabled"
            label="LBL0001056"
            name="remark"
            v-model="assessPlan.remark">
          </c-textarea>
        </div>
        <div v-if="isOcc&&isOld" class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <!-- 수시 변경/추가 내역 -->
          <c-table
            ref="table2"
            title="LBL0001061"
            :columns="gridOcc.columns"
            :gridHeight="gridOcc.height"
            :data="assessPlan.occasions"
            :filtering="false"
            :columnSetting="false"
            :usePaging="false"
            :editable="editable&&!disabled"
            selection="multiple"
            rowKey="ramAssessmentOccasionId"
          >
            <!-- 버튼 영역 -->
            <template slot="table-button">
              <q-btn-group outline >
                <c-btn 
                  v-if="editable&&!disabled" 
                  :showLoading="false" 
                  label="LBLADD" 
                  icon="add" 
                  @btnClicked="addOccasion" />
                <c-btn 
                  v-if="editable&&!disabled" 
                  :disabled="!assessPlan.occasions || assessPlan.occasions.length === 0"
                  label="제외" 
                  icon="remove" 
                  @btnClicked="deleteAssessOccasion" />
              </q-btn-group>
            </template>
          </c-table>
        </div>
        <div v-if="isOld" class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
          <!-- 평가팀 목록 -->
          <c-table
            ref="table1"
            title="LBL0001062"
            :columns="grid.columns"
            :gridHeight="grid.height"
            :data="assessPlan.teams"
            :filtering="false"
            :columnSetting="false"
            :usePaging="false"
            :editable="editable&&!disabled"
            selection="multiple"
            rowKey="ramAssessmentTeamId"
          >
            <!-- 버튼 영역 -->
            <template slot="table-button">
              <q-btn-group outline >
                <c-btn 
                  v-if="editable&&!disabled" 
                  :showLoading="false" 
                  label="LBLADD" 
                  icon="add" 
                  @btnClicked="addTeam" />
                <c-btn 
                  v-if="editable&&!disabled" 
                  :disabled="!assessPlan.teams || assessPlan.teams.length === 0"
                  label="제외" 
                  icon="remove" 
                  @btnClicked="deleteAssessTeam" />
              </q-btn-group>
            </template>
          </c-table>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
          <c-upload 
            class="q-pt-md"
            :attachInfo="attachInfo"
            :editable="editable&&!disabled">
          </c-upload>
        </div>
      </template>
    </c-card>
    <c-dialog :param="popupOptions"></c-dialog>
  </q-form>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'riskPlanInfo',
  props: {
    assessPlan: {
      type: Object,
      default: () => ({
        ramRiskAssessmentPlanId: '',  // 평가 일련번호
        plantCd: '',  // 사업장 코드
        ramTechniqueCd: '',  // 평가기법 코드_HAZOP/KRAS/JSA/CHECKLIST
        ramAssessTypeCd: '',  // 평가구분_최초/정기/수시
        assessmentName: '',  // 평가명
        assessmentStartDate: '',  // 평가시작일
        assessmentEndDate: '',  // 평가종료일
        assessmentPeriod: [],
        assessmentYear: '',  // 평가년도
        ramStepCd: '',  // 평가 진행단계 코드
        assessmentManageDeptCd: '',  // 평가주관 부서 코드
        assessmentManageUserId: '',  // 평가주관 담당자 ID
        ramMatrixId: null,  // 위험메트릭스 번호
        sopMocId: '',  // MOC 일련번호
        remark: '',  // 상세내용
        workArea: '',  // 작업지역
        regDtStr: '',
        regUserId: '',  // 작성자 ID
        regUserName: '',  // 작성자 ID
        reviewUserId: '',  // 검토자 ID
        reviewDate: '',  // 검토일
        approvalUserId: '',  // 승인자 ID
        approvalDate: '',  // 승인일
        vendorCd: '',
        sopConstructionId: '',
        chgUserId: '',  // 수정자 ID
        teams: [], // 평가팀 목록
        deleteTeams: [], // 평가팀 목록
        occasions: [], // 수시 변경/추가 내역 목록
        deleteOccasions: [], // 수시 변경/추가 내역 목록
        conferences: [], // 회의 목록
        relatedDocuments: [], // 관련자료 목록
        processes: [], // 대상공정 목록
      }),
    },
    attachInfo: {
      type: Object,
      default: () => ({
        isSubmit: '',
        taskClassCd: 'RISK_PLAN',
        taskKey: '',
      }),
    },
    updateBtnData: {
      type: Object,
      default: () => ({
        flag: false,
      }),
    },
    vendorFlag: {
      type: Boolean,
      default: () => ({
        flag: false,
      }),
    },
  },
  data() {
    return {
      matrixItems: [],
      grid: {
        columns: [],
        height: '400px'
      },
      gridOcc: {
        columns: [
          {
            name: 'taskClassName',
            field: 'taskClassName',
            label: 'LBLWORK',
            align: 'center',
            style: 'width:15%',
            sortable: false,
          },
          {
            name: 'taskTitle',
            field: 'taskTitle',
            label: 'LBLTITLE',
            align: 'left',
            style: 'width:30%',
            sortable: false,
          },
          {
            name: 'remarks',
            field: 'remarks',
            label: 'LBLWORK',
            align: 'left',
            style: 'width:55%',
            type: 'textarea',
            sortable: false,
          },
        ],
        height: '250px'
      },
      editable: true,
      matrixListUrl: '',
      saveUrl: '',
      isSave: false,
      mappingType: 'POST',
      ramRiskAssessmentPlanId: '',
      saveCallData: '',
      popupOptions: {
        isApproval: true,
        target: null,
        title: '',
        visible: false,
        param: {},
        closeCallback: null,
      },
    };
  },
  watch: {
    'assessPlan.plantCd'() {
      this.setMatrixItems(); 
    }
  },
  computed: {
    isOld() {
      return Boolean(this.assessPlan.ramRiskAssessmentPlanId)
    },
    isOcc() {
      return this.assessPlan.ramAssessTypeCd === 'RAT0000005'
    },
    request() {
      return this.assessPlan.regUserName + ' / ' + this.assessPlan.regDtStr
    },
    disabled() {
      return Boolean(this.assessPlan.ramStepCd) 
        && this.assessPlan.ramStepCd !== 'RRS0000001' 
        && !this.updateBtnData.flag
    },
    techCheck() {
      // HAZOP, CHARM만 기능 활성화
      return false
      // return this.assessPlan.ramTechniqueCd === 'RT00000001'
      //   || this.assessPlan.ramTechniqueCd === 'RT00000030'
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.matrixListUrl = selectConfig.ram.matrix.list.url
      this.saveUrl = transactionConfig.ram.assessPlan.insert.url
      // code setting
      this.setMatrixItems(); 
      this.setTeamHeader(); 
      // list setting
    },
    setMatrixItems() {
      // K-PSR은 위험도 측정을 하지 않음
      if (this.assessPlan.ramTechniqueCd === 'RT00000005') return
      this.$http.url = this.matrixListUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: this.assessPlan.plantCd,
        ramTechniqueCd: this.assessPlan.ramTechniqueCd,
      }
      this.$http.request((_result) => {
        this.matrixItems = _result.data;
      },);
    },
    setTeamHeader() {
      this.$comm.getMultiComboItems(['RAM_ROLE_TYPE_CD', 'COM_INSIDE_OUTSIDE_CD']).then(_result => {
        let cols = [
          {
            name: 'ramRoleTypeCd',
            field: 'ramRoleTypeCd',
            // 역할
            label: 'LBLROLE',
            align: 'center',
            style: !this.vendorFlag ? 'width:20%' : 'width:30%',
            type: 'select',
            comboItems: _result['RAM_ROLE_TYPE_CD'],
            sortable: false,
            required: true,
          },
          {
            name: 'etcRole',
            field: 'etcRole',
            // 기타 역할<br/>(역할이 \'기타\' 선택 될 시)
            label: 'LBL0001058',
            align: 'left',
            style: !this.vendorFlag ? 'width:20%' : 'width:30%',
            type: 'text',
            sortable: false,
            disableTarget: 'ramRoleTypeCd',
            disableCon: 'RRT0000010',
          },
          {
            name: 'userName',
            field: 'userName',
            label: 'LBLNAME',
            align: 'center',
            style: !this.vendorFlag ? 'width:20%' : 'width:40%',
            type: !this.vendorFlag ? 'user' : 'text',
            userId: 'userId',
            sortable: false,
            isInout: true,
            inoutCol: 'ramInsideOutsideCd',
            in: '1',
            out: '2',
            relationCols: ['deptCd', 'deptName'],
            required: true,
          },
        ];
        if (!this.vendorFlag) {
          cols.push({
            name: 'deptName',
            field: 'deptName',
            label: 'LBLDEPT',
            align: 'center',
            style: 'width:20%',
            sortable: false,
          })
          cols.splice(2, 0, {
            name: 'ramInsideOutsideCd',
            field: 'ramInsideOutsideCd',
            // 내외부구분
            label: 'LBL0001059',
            align: 'center',
            style: 'width:20%',
            type: 'select',
            comboItems: _result['COM_INSIDE_OUTSIDE_CD'],
            none: true,
            sortable: false,
          },)
        }
        this.grid.columns = cols;
      });
    },
    loadAssessPlan() {
      this.popupOptions.title = "LBLLOAD"; // 불러오기
      this.popupOptions.param = {
        type: "single",
        ramTechniqueCd: this.assessPlan.ramTechniqueCd,
        // regUserId: this.$store.getters.user.userId
      };
      this.popupOptions.target = () => import(`${"@/pages/ram/loadAssessmentPop.vue"}`);
      this.popupOptions.width = "70%";
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeLoadPopup;
    },
    closeLoadPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$emit('loadAssess', data[0].ramRiskAssessmentPlanId)
      }
    },
    saveAssessPlan() {
      if (this.assessPlan.ramRiskAssessmentPlanId) {
        this.saveUrl = transactionConfig.ram.assessPlan.update.url
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.ram.assessPlan.insert.url
        this.mappingType = 'POST';
        this.assessPlan.ramStepCd = 'RRS0000001' // 평가계획
      }

      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.assessPlan.regUserId = this.$store.getters.user.userId
              this.assessPlan.chgUserId = this.$store.getters.user.userId

              this.assessPlan.assessmentStartDate = this.assessPlan.assessmentPeriod[0]
              this.assessPlan.assessmentEndDate = this.assessPlan.assessmentPeriod[1]

              this.isSave = !this.isSave
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.ramRiskAssessmentPlanId = result.data
      this.$set(this.attachInfo, 'taskKey', result.data)
      this.$set(this.attachInfo, 'isSubmit', uid())
      this.saveCallData = uid();
      this.$emit('saveCallback', result.data)
    },
    addOccasion() {
      this.popupOptions.title = 'LBL0001060'; // 변경/추가 내역 검색
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.target = () => import(`${'@/pages/ram/riskOccasion.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeOccasionPopup;
    },
    closeOccasionPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.assessPlan.occasions, { 
            taskId: item.taskId, 
            taskClassName: item.taskClassName 
          }) === -1) {
            this.assessPlan.occasions.splice(0, 0, { 
              ramAssessmentOccasionId: uid(),  // 수시 변경/추가 내역 번호
              ramRiskAssessmentPlanId: this.assessPlan.ramRiskAssessmentPlanId,  // 평가 일련번호
              taskClassName: item.taskClassName,  // 설비, 기계기구, 공정, 화학자재, 사고
              taskId: item.taskId,  // 업무 id
              taskTitle: item.taskTitle,  // 업무 제목
              remarks: '',  // 비고
              editFlag: 'C', regUserId: this.$store.getters.user.userId,
            })
          }
        })
      }
    },
    deleteAssessOccasion() {
      let selectData = this.$refs['table2'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('APP_REQUEST_SUCCESS');
        if (!this.assessPlan.deleteOccasions) this.assessPlan.deleteOccasions = [];
        this.$_.forEach(selectData, item => {
          if (this.$_.findIndex(this.assessPlan.deleteOccasions, { ramAssessmentOccasionId: item.ramAssessmentOccasionId }) === -1
            && item.editFlag !== 'C') {
            this.assessPlan.deleteOccasions.push(item)
          }
          this.assessPlan.occasions = this.$_.reject(this.assessPlan.occasions, item);
        })
        this.$refs['table2'].$refs['compo-table'].clearSelection();
      }
    },
    addTeam() {
      this.assessPlan.teams.splice(0, 0, {
        ramAssessmentTeamId: uid(),
        ramInsideOutsideCd: '1',
        ramRoleTypeCd: null, userId: '', userName: '', deptName: '', etcRole: '',
        editFlag: 'C', regUserId: this.$store.getters.user.userId,
        ramRiskAssessmentPlanId: this.assessPlan.ramRiskAssessmentPlanId
      })
    },
    deleteAssessTeam() {
      let selectData = this.$refs['table1'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('APP_REQUEST_SUCCESS');
        
        if (!this.assessPlan.deleteTeams) this.assessPlan.deleteTeams = [];
        this.$_.forEach(selectData, item => {
          if (this.$_.findIndex(this.assessPlan.deleteTeams, { ramAssessmentTeamId: item.ramAssessmentTeamId }) === -1
            && item.editFlag !== 'C') {
            this.assessPlan.deleteTeams.push(item)
          }
          this.assessPlan.teams = this.$_.reject(this.assessPlan.teams, item);
        })
        this.$refs['table1'].$refs['compo-table'].clearSelection();
      }
    },
  }
};
</script>
